body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 84px;
  overflow-x: hidden;
  background: #fff;
  scroll-behavior: smooth;
}

*{
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bottom-fix {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
}
.btn-full {
  border-radius: 0 !important;
}

.page-title {
  background: #eee;
  padding: 15px 0;
  margin-bottom: 15px;
}
.page-title h1 {
  font-weight: bold;
}

.link {
  cursor: pointer;
  transition: 0.3s;
}
.link:hover {
  background: #eee;
  color: #4a7b04;
}
.jDHQPG {
  display: block !important;
}

.navbar input {
  border: none;
  margin-bottom: -4px;
}
.suggest {
  position: absolute !important;
  width: 800px !important;
  background: #fff;
  top: 37px;
  left: 16px;
  box-shadow: 0 0 4px #999;
  border-radius: 10px;
  border-top-left-radius: 0;
}
@media (max-width: 900px) {
  .suggest {
    width: 500px !important;
  }
}
@media (max-width: 420px) {
  .suggest {
    width: 360px !important;
  }
}
.suggest .rdt_TableRow {
  cursor: pointer;
  transition: all 0.3s;
}
.suggest .rdt_TableRow:hover {
  background: #eee;
  color: #4a7b04;
}
.alert-dark {
  color: #1b1e21;
  background-color: #f8f9fa;
  border-color: #eee;
}
.border-vertical {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.text-grey {
  color: #ccc;
}
.table-bordered th {
  border: 0;
}
.table thead th {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-color: #eee;
}
.react-bs-table .table-bordered > tbody > tr > td {
  font-size: 14px;
}
.campaignTitle,
.campaignTitle a {
  color: #1d4091;
  text-transform: uppercase;
}
.table thead th.h-border {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.table thead th.l-border {
  border-left: 1px solid #eee;
}
.table tbody td {
  font-weight: 500;
}
.verticalAlignSub {
  vertical-align: sub !important;
}

.inputRead {
  width: 100%;
  padding: 2px;
  font-size: 12px;
}
.breadcrumb-item a {
  font-weight: bold;
  color: #000;
}
.react-bs-table-bordered {
  border-radius: 0 !important;
}
.react-bs-table-sizePerPage-dropdown .btn {
  padding: 5px 15px;
  margin-top: 0;
}

.pagination > .page-item.active > a,
.pagination > .page-item.active > a:focus,
.pagination > .page-item.active > a:hover,
.pagination > .page-item.active > span,
.pagination > .page-item.active > span:focus,
.pagination > .page-item.active > span:hover {
  background-color: #1d4090;
  border-color: #0f3690;
}
.table-row-click tr {
  cursor: pointer;
}

/* material-kit.min.css | http://localhost:3000/assets/css/material-kit.min.css */

.dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item,
.dropdown-menu li > a {
  min-width: 1rem;
  padding: 0;
}

@media (min-width: 768px) {
  .dropdown-menu .dropdown-item,
  .dropdown-menu li > a {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.dropdown-menu {
  min-width: 2rem;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu a:active,
.dropdown-menu a:focus,
.dropdown-menu a:hover {
  background-color: #1d4090;
}
.react-bs-table table td,
.react-bs-table table th {
  white-space: initial !important;
  text-overflow: initial !important;
}

.react-bs-table-container .react-bs-table-search-form {
  border-radius: 50px;
  border: 1px solid #ccc;
  padding: 5px 15px;
}

.form-group-sm > input:nth-child(1) {
  border: none;
  background: none;
  line-height: normal;
  height: auto;
}

.togglebutton label input[type="checkbox"]:checked + .toggle {
  background-color: rgba(29, 64, 144, 0.7);
}

.togglebutton label input[type="checkbox"]:checked + .toggle::after {
  border-color: #1d4090;
}
.card-header {
  padding: 0;
}
.card-header .card-title {
  margin: 0;
  padding: 5px;
}
.text-default {
  color: #999;
}
strong,
.strong {
  font-weight: 700;
}
.text-success-dark {
  color: #1d4090;
}
.btn-busca {
  position: absolute;
  right: 0;
  top: 0;
  height: 39px;
  width: 40px;
  font-size: 20px;
  border-radius: 50%;
  background: #fff;
  line-height: 49px;
  border: none;
  box-shadow: 0 2px 2px 0 hsla(0, 0%, 60%, 0.14),
    0 3px 1px -2px hsla(0, 0%, 60%, 0.2), 0 1px 5px 0 hsla(0, 0%, 60%, 0.12);
  color: #999;
}
.campo-busca {
  margin-bottom: -1px;
  padding-right: 20px;
  height: 40px;
}
.react-bs-table-container .react-bs-table-search-form {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 3px;
}




/* Largura da scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

/* Track (área da scrollbar que não está sendo usada) */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Thumb (a parte "arrastável" da scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #1d4091;  
}

/* Thumb ao passar o mouse por cima */
::-webkit-scrollbar-thumb:hover {
  background-color: #0f2a6b;
}
.order{
	position: relative;
	bottom: 0;
	right:0;
	margin: 5px !important;
}
.order .caret{
	font-size: 7px;
}
.order .caret:after{	
	content: "▼";
}
.order .dropup .caret:after,
.order.dropup .caret:after{
	content: "▲";
}